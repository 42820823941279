<template>
  <vx-card :title="widgetData.name" :subtitle="widgetData.title">
    <template slot="actions" >
      <vs-dropdown vs-trigger-click class="cursor-pointer">
        <small class="flex cursor-pointer">
          <feather-icon icon="MoreVerticalIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
        </small>
        <vs-dropdown-menu class="w-32">
          <vs-dropdown-item @click="toggleGraph">{{ nextGraph }}</vs-dropdown-item>
          <template  v-if="!isReadonly">
            <vs-dropdown-item @click="handleEdit">Edit</vs-dropdown-item>
          <vs-dropdown-item @click="handleRemove">Remove</vs-dropdown-item>
          </template>
        </vs-dropdown-menu>
      </vs-dropdown>
    </template>
    
    <search-duration-table v-if="graphType === 'Table'"  :chartData="chartData" :options="chartOptions"></search-duration-table>
    <search-duration-bar-chart v-if="graphType === 'Bar'"  :chartData="chartData" :options="chartOptions"></search-duration-bar-chart>
    <div class="mt-2 text-xs italic">Represents number of days between Search date and Travel date</div>
  </vx-card>
</template>

<script>
import SearchDurationBarChart from './SearchDurationBarChart.vue'
import SearchDurationTable from './SearchDurationTable.vue'
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
export default {
  name: 'search-duration-widget',
  mixins: [TpNotificationMixin],
  components: {
    SearchDurationBarChart,
    SearchDurationTable
  },
  props: {
    dashboardId: { type: Number, required: true},
    widgetData: { type: Object },
    chartData: { type: Array },
    chartOptions: { type: Object },
    isReadonly: { type: Boolean, required: true },
    applicationId: { type: Number},
    isCompanyDashboard: { type: Boolean, required: true}
  },
  data() {
    return {
      graphType: "Bar",
      nextGraph : "Table"
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    handleEdit () {
      let routeParams = { 
        companyId: this.activeUserInfo.companyId,
        dashboardId: this.dashboardId,
        widgetInstanceId: this.widgetData.instanceId,
        componentEditor: 'search-duration-widget-editor',
        apiEndpoint: 'searchDuration',
        widgetId: this.widgetData.templateId,
        widgetName: this.widgetData.name,
      }

      let routeName = 'company-dashboard-widget-edit'
      if (!this.isCompanyDashboard) {
        routeName = 'application-dashboard-widget-edit'
        routeParams.applicationId = this.applicationId
      }
      this.$router.push({ name: routeName , params: routeParams })
      
    },
    handleRemove () {
      this.$emit('deleteWidget', this.widgetData.instanceId)
    },
    toggleGraph () {
      let currentGraph = this.graphType;
      this.graphType = this.nextGraph;
      this.nextGraph = currentGraph;
    }
  }
  
}
</script>

<style>

</style>