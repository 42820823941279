<template>
  <vs-table :data="chartData">

    <template slot="thead">
      <vs-th> </vs-th>
      <vs-th v-for="(category, index) in options.xAxisCategories" :key="index">{{ category }}</vs-th>
    </template>

    <template slot-scope="{data}">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data">

        <vs-td :data="data[indextr].name">
          {{ data[indextr].name }}
        </vs-td>

        <vs-td :key="indextd" v-for="(td, indextd) in data[indextr].data">
          {{ data[indextr].data[indextd] }}
        </vs-td>
      </vs-tr>
    </template>

  </vs-table>
</template>

<script>


export default {
  name: "search-duration-table",
  components: {
  },
  props: {
    chartData: { type: Array },
    options: { type: Object }
  },
  
  data() {
    return {
      showDeleteDialog: false,
    };
  },
};
</script>