<template>
  <vue-apex-charts type="bar" height="350" :options="chartOptions" :series="chartData"></vue-apex-charts>
</template>

<script>
// See docs for more options https://apexcharts.com/docs/options/
import VueApexCharts from 'vue-apexcharts'

const themeColors = ['#7367F0', '#28C76F','#EA5455', '#FF9F43', '#1E1E1E',  '#F44336', '#FFEBEE','#FFCDD2','#EF9A9A','#E57373','#EF5350','#F44336','#E53935','#D32F2F','#C62828','#B71C1C','#FF8A80','#FF5252','#FF1744','#D50000','#E91E63','#FCE4EC','#F8BBD0','#F48FB1','#F06292','#EC407A','#E91E63','#D81B60','#C2185B','#AD1457','#880E4F','#FF80AB','#FF4081','#F50057','#C51162','#9C27B0','#F3E5F5']

export default {
  // extends: Bar,
  name: 'traveller-count-barchart',
  components: {
    VueApexCharts
  },
  props: {
    chartData: { type: Array },
    options: { type: Object }
  },
  data () { 
    return {
      chartOptions: {
        colors: themeColors,
        plotOptions: {
          bar: {
            horizontal: false,
            // endingShape: 'rounded',
            columnWidth: '55%'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: this.options.xAxisCategories,
          hideOverlappingLabels: true
        },
        yaxis: {
          // title: {
          //   text: '$ (thousands)'
          // }
        },
        fill: {
          opacity: 1

        },
        // https://apexcharts.com/docs/options/grid/
        grid: { 
          show: true,
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
                show: false
            }
          },   
        },
        tooltip: {
          y: {
            formatter (val) {
              return `${val} searche(s)`
            }
          }
        }
      }
    }
  },

  mounted () {
    // this.renderChart(this.chartdata, this.options)
  },
   watch: {
    options: {
      deep: true,
      immediate: true,
      handler: function (newValue, oldValue) {
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              categories: this.options.xAxisCategories,
              hideOverlappingLabels: true,
            },
          },
        };
      },
    },
  },
}
</script>

<style>

</style>

