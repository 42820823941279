<template>
  <vx-card :title="widgetData.name" :subtitle="widgetData.title">
    <template slot="actions" v-if="!isReadonly">
      <vs-dropdown vs-trigger-click class="cursor-pointer">
        <small class="flex cursor-pointer">
          <feather-icon icon="MoreVerticalIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
        </small>
        
        <vs-dropdown-menu class="w-32">
          <vs-dropdown-item @click="handleEdit">Edit</vs-dropdown-item>
          <vs-dropdown-item @click="handleRemove">Remove</vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
      

    </template>
    <search-location-chart :series="chartData"></search-location-chart>
  </vx-card>
</template>

<script>
import SearchLocationChart from './SearchLocationChart.vue'
export default {
  name: 'search-location-widget',
  components: {
    SearchLocationChart
  },
  props: {
    dashboardId: { type: Number, required: true},
    widgetData: { type: Object },
    chartData: { type: Array },
    chartOptions: { type: Object },
    isReadonly: { type: Boolean, required: true },
    applicationId: { type: Number},
    isCompanyDashboard: { type: Boolean, required: true}
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    handleEdit () {
      let routeParams = { 
        companyId: this.activeUserInfo.companyId,
        dashboardId: this.dashboardId,
        widgetInstanceId: this.widgetData.instanceId,
        componentEditor: 'search-location-widget-editor',
        apiEndpoint: 'searchLocation',
        widgetId: this.widgetData.templateId,
        widgetName: this.widgetData.name
      }
      
      let routeName = 'company-dashboard-widget-edit'
      if (!this.isCompanyDashboard) {
        routeName = 'application-dashboard-widget-edit'
        routeParams.applicationId = this.applicationId
      }
      this.$router.push({ name: routeName , params: routeParams })
    },
    handleRemove () {
      this.$emit('deleteWidget', this.widgetData.instanceId)
    }
  }
  
}
</script>

<style>

</style>