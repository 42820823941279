<template>
  <div class="vx-row">
    <div class="vx-col w-full" v-for="(item, index) in chartData" :key="index">
      <div class="mb-1">{{item.name}}</div>
    </div>
  </div>

</template>

<script>


export default {
  name: "top-search-location-table",
  components: {
  },
  props: {
    chartData: { type: Array },
    options: { type: Object }
  },
  
  data() {
    return {
      showDeleteDialog: false,
    };
  },
};
</script>