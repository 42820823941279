var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-table",
    {
      attrs: { data: _vm.chartData },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function ({ data }) {
            return _vm._l(data, function (tr, indextr) {
              return _c(
                "vs-tr",
                { key: indextr },
                [
                  _c("vs-td", { attrs: { data: data[indextr].statusLabel } }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(data[indextr].statusLabel) +
                        "\n      "
                    ),
                  ]),
                  _vm._l(data[indextr].totals, function (td, indextd) {
                    return _c(
                      "vs-td",
                      { key: indextd },
                      [
                        _vm._l(td.currencyTotals, function (item, indexItem) {
                          return _c("div", { key: indexItem }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(item.count) +
                                  " (" +
                                  _vm._s(_vm.getCurrencySymbol(item.currency)) +
                                  " " +
                                  _vm._s(
                                    item.amount &&
                                      item.amount
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  ) +
                                  ")"
                              ),
                            ]),
                          ])
                        }),
                        !td.currencyTotals || td.currencyTotals.length == 0
                          ? _c("div", [_c("span", [_vm._v("-")])])
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                ],
                2
              )
            })
          },
        },
      ]),
    },
    [
      _c(
        "template",
        { slot: "thead" },
        [
          _c("vs-th"),
          _vm._l(_vm.options.xAxisCategories, function (category, index) {
            return _c("vs-th", { key: index }, [_vm._v(_vm._s(category))])
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }