var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: _vm.widgetData.name, subtitle: _vm.widgetData.title } },
    [
      _c(
        "template",
        { slot: "actions" },
        [
          _c(
            "vs-dropdown",
            {
              staticClass: "cursor-pointer",
              attrs: { "vs-trigger-click": "" },
            },
            [
              _c(
                "small",
                { staticClass: "flex cursor-pointer" },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: "MoreVerticalIcon",
                      svgClasses: "w-6 h-6 text-grey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-dropdown-menu",
                { staticClass: "w-32" },
                [
                  !_vm.isReadonly
                    ? [
                        _c(
                          "vs-dropdown-item",
                          { on: { click: _vm.handleEdit } },
                          [_vm._v("Edit")]
                        ),
                        _c(
                          "vs-dropdown-item",
                          { on: { click: _vm.handleRemove } },
                          [_vm._v("Remove")]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("bookings-baskets-table", {
        attrs: { chartData: _vm.chartData, options: _vm.chartOptions },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }