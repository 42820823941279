<template>
   <!-- <GChart
    type="GeoChart"
    :data="chartData"
    :options="chartOptions"
  /> -->
    <GChart
      :settings="{ packages: ['geochart'], mapsApiKey: mapsApiKey }"
      type="GeoChart"
      :data="series"
      :options="chartOptions"
    />
</template>

<script>
// See https://github.com/devstark-com/vue-google-charts and
// https://developers.google.com/chart/interactive/docs/gallery/geochart
import { GChart } from "vue-google-charts";

export default {
  props: {
    series: { type: Array }
  },
  components: {
    GChart
  },
  data () {
    return {
      mapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      chartOptions: {
        // sizeAxis: { minValue: 0, maxValue: 100 },
        // region: '155', // Western Europe
        height: 400,
        displayMode: 'markers',
        colorAxis: {minValue: 0, colors: ['#e7711c', '#4374e0']} // orange to blue
      }
    }
  }
}
</script>

<style>

</style>