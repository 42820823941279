var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vx-row" },
    _vm._l(_vm.chartData, function (item, index) {
      return _c("div", { key: index, staticClass: "vx-col w-full" }, [
        _c("div", { staticClass: "mb-1" }, [_vm._v(_vm._s(item.name))]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }