<template>
  <vx-card title="Website Statistics" subtitle="">
    <template slot="actions" >
      <a v-if="appStatsUrlLink" :href="appStatsUrlLink" target="_blank"
        style="cursor: pointer;">
        View full stats (opens in new window)
      </a>
    </template>
    <iframe v-if="appStatsUrl" id="appStatsFrame" :src="appStatsUrl" width="100%" height="650px" style="border:0 solid #999;margin:0 auto;" ref="appStatsFrame">
    </iframe>   
  </vx-card>
</template>

<script>
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
export default {
  mixins: [TpNotificationMixin],
  components: {
  },
  props: {
    applicationId: { type: Number},
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    appStatsUrl () {
      return this.$store.state.ActiveApplicationStatsUrl;
    },
    appStatsUrlLink () {
      return this.$store.state.ActiveApplicationStatsUrlLink;
    },
  },
  methods: {
  }
  
}
</script>

<style>

</style>
