<template>
  <div>
    <div id="dashboard-analytics">
      <div class="vx-row match-height">
        <div class="vx-col w-full mb-base" 
          :class="widget.cssGridSettings"
          v-for="(widget, index) in widgetList" 
          :key="index">
            <!-- https://stackoverflow.com/questions/43658481/passing-props-dynamically-to-dynamic-component-in-vuejs -->
              <component 
                :is="widget.componentName" 
                @deleteWidget="handleRemoveWidget(widget.instanceId)"
                v-bind="{ 
                  dashboardId: dashboardId,
                  chartData: widget.chartData,
                  chartOptions: widget.chartOptions,
                  isReadonly: isDashboardReadonly,
                  isCompanyDashboard: isCompanyDashboard,
                  applicationId: applicationId,
                  widgetData: {
                    templateId: widget.templateId,
                    instanceId: widget.instanceId,
                    name: widget.name,
                    title: widget.title}
                }">
              </component>
        </div>
        <div v-if="!isCompanyDashboard && isDashboardDefault && appStatsUrl" class="vx-col w-full mb-base">
          <clicky-analytics-display
            :applicationId="applicationId"
          >
          </clicky-analytics-display>
        </div>
      </div>
    </div>
    <vs-prompt
      title="Remove Widget"
      accept-text="Remove"
      @cancel="handleCancelDeleteWidget"
      @close="handleCancelDeleteWidget"
      @accept="deleteWidget"
      :active.sync="showRemoveWidgetPopup">

        <div class="vx-row mt-2 mb-2">
          <div class="vx-col w-full">
            <div class="">Are you sure you want to remove the widget '{{currentWidgetForDeletion && currentWidgetForDeletion.title}}'?</div>
          </div>
        </div>
    </vs-prompt>
  </div>
</template>

<script>
import SearchTypeWidget from './../widgets/search-type/SearchTypeWidgetDisplay.vue'
import SearchLocationWidget from './../widgets/search-location-map/SearchLocationWidgetDisplay.vue'
import FlightDepartureWidget from './../widgets/flight-departure/FlightDepartureWidgetDisplay.vue'
import FlightArrivalWidget from './../widgets/flight-arrival/FlightArrivalWidgetDisplay.vue'
import FlightRouteWidget from './../widgets/flight-route/FlightRouteWidgetDisplay.vue'
import TravelDurationWidget from './../widgets/travel-duration/TravelDurationWidgetDisplay.vue'
import TravellerCountWidget from './../widgets/traveller-count/TravellerCountWidgetDisplay.vue'
import SearchDurationWidget from './../widgets/search-duration/SearchDurationWidgetDisplay.vue'
import ClickyAnalyticsDisplay from './../widgets/clicky-analytics/ClickyAnalyticsDisplay.vue'
import BookingsBasketsWidget from './../widgets/bookings-baskets/BookingsBasketsWidgetDisplay.vue'
import TopSearchLocationWidget from './../widgets/top-search-location/TopSearchLocationDisplay.vue'

export default {
  components: {
    SearchTypeWidget,
    SearchLocationWidget,
    FlightDepartureWidget,
    FlightArrivalWidget,
    FlightRouteWidget,
    TravelDurationWidget,
    TravellerCountWidget,
    SearchDurationWidget,
    ClickyAnalyticsDisplay,
    BookingsBasketsWidget,
    TopSearchLocationWidget
  },
  data () {
    return {
      widgetInstanceIdToBeDeleted: null,
      showRemoveWidgetPopup: false,
    }
  },
  props: {
    dashboardId: { required: true },
    widgetList: { type: Array, required: true },
    isCompanyDashboard: { type: Boolean, default: true },
    isDashboardReadonly: { type: Boolean, required: true },
    isDashboardDefault: { type: Boolean, required: false },
    applicationId: { required: false },

  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    dashboardStore () {
      return this.isCompanyDashboard
				? this.$store.state.companyDashboardStore
				: this.$store.state.applicationDashboardStore
    },
    currentWidgetForDeletion () {
      const widgetIndex = this.$_.findIndex(this.dashboardStore.widgets, 
        x => x.instanceId === this.widgetInstanceIdToBeDeleted)
      return this.dashboardStore.widgets[widgetIndex];
    },
    appStatsUrl () {
      return this.$store.state.ActiveApplicationStatsUrl;
    },
  },
  methods: {
    deleteWidget () {
      const storeName = this.isCompanyDashboard ? 'companyDashboardStore' : 'applicationDashboardStore'
      const actionParams = this.isCompanyDashboard
        ? { companyId: this.activeUserInfo.companyId, widgetInstanceId: this.widgetInstanceIdToBeDeleted }
        : { applicationId: this.applicationId, widgetInstanceId: this.widgetInstanceIdToBeDeleted }

      this.$vs.loading();
      this.$store.dispatch(`${storeName}/removeWidget`, actionParams )
      .then(response => {
        this.widgetInstanceIdToBeDeleted = null
        this.$_notifySuccess('Successfully removed widget')
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data) )
      .finally(() => this.$vs.loading.close())
    },
    handleRemoveWidget (widgetInstaceId) {
      this.widgetInstanceIdToBeDeleted = widgetInstaceId;
      this.showRemoveWidgetPopup = true
    },
    handleCancelDeleteWidget () {
      this.widgetInstanceIdToBeDeleted = null
    },
  }
}
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
</style>