<template>
  <div class="vx-row">
    <div class="vx-col w-full md:w-1/2 lg:w-1/4 company-dashboard-select-container">
      <v-select class="mb-4" :clearable="false" 
        :value="selectedDashboard"
        @input="$emit('set-selected-dashboard', $event)" 
        :options="dashboardList" />
    </div>
    <div class="vx-col w-full md:w-1/2 lg:w-3/4 lg:pl-0 mb-4">
      <div class="flex flex-wrap items-center">
        <vs-button @click="showAddDashboardPopup=true" type="border" icon-pack="feather" icon="icon-plus" title="Add New Dashboard"></vs-button> 
        <vs-button @click="editDashboard" :disabled="isDashboardReadonly" class="ml-2" type="border" icon-pack="feather" icon="icon-edit-2" title="Edit Dashboard"></vs-button> 
        <vs-button @click="showDeleteDashboardPopup=true" :disabled="isDashboardReadonly" class="ml-2" type="border" icon-pack="feather" icon="icon-trash" title="Delete Dashboard"></vs-button> 
        <vs-button @click="showAddWigetPopup=true" :disabled="isDashboardReadonly" class="ml-2" type="border" title="Add Widget To Current Dashboard">Add Widget</vs-button>
      </div>
    </div>

		<vs-prompt
      title="Add Dashboard"
      accept-text="Add"
      @cancel="closeAddDashboardDialog"
      @close="closeAddDashboardDialog"
      @accept="addDashboard"
      :active.sync="showAddDashboardPopup">

        <div class="vx-row mt-2 mb-2">
          <div class="vx-col w-full">
            <label class="text-sm">Dahboard Name:</label>
            <vs-input
                name="Dashboard"
                placeholder="Dahboard Name"
                v-model="newDashboardName"
                class="w-full"/>
          </div>

          <div class="vx-col w-full mt-2">
            <label class="text-sm">Description:</label>
            <vs-textarea 
              name="Description"
              placeholder="Description"
              counter="256"
              maxlength="256"
              rows="3" 
              v-model="newDashboardDescription"
              class="w-full"/>
          </div>
        </div>
      </vs-prompt>
    
    <vs-prompt
      title="Delete Dashboard"
      accept-text="Delete"
      @accept="deleteDashboard"
      :active.sync="showDeleteDashboardPopup">

        <div class="vx-row mt-2 mb-2">
          <div class="vx-col w-full">
            <div class="">Are you sure you want to delete the current dashboard?</div>
          </div>
        </div>
    </vs-prompt>

    <vs-prompt
      title="Add Widget"
      accept-text="Add"
      @cancel="closeAddWidgetDialog"
      @close="closeAddWidgetDialog"
      @accept="addWidget"
      :active.sync="showAddWigetPopup">

        <div class="vx-row mt-2 mb-4">
          <div class="vx-col w-full">
            <label class="text-sm">Select Widget:</label>
            <v-select v-model="selectedWidget" :options="widgetOptions" />
          </div>
        </div>
      </vs-prompt>
  </div>
</template>

<script>
export default {
  props: {
    isCompanyDashboard: { type: Boolean, default: true },
    applicationId: { required: false }
	},
	data () {
		return {
			showAddDashboardPopup: false,
			newDashboardName: '',
      newDashboardDescription: '',
      showDeleteDashboardPopup: false,
      showAddWigetPopup: false,
      selectedWidget: null
		}
	},
	computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    dashboardStore () {
      return this.isCompanyDashboard
				? this.$store.state.companyDashboardStore
				: this.$store.state.applicationDashboardStore
    },
    activeDashboard () {
      return this.dashboardStore.activeDashboard
    },
    dashboardList () {
      return this.$_.map(this.dashboardStore.dashboards, x => {
        return { code: x.id, label: x.name }
      })
    },
    selectedDashboard () {
      const val = this.activeDashboard
      return val ? { code: val.id, label: val.name } : {}
    },
    isDashboardReadonly () {
      return this.isCompanyDashboard
        ? this.dashboardStore.activeDashboard && this.dashboardStore.activeDashboard.companyId === 0
        : this.dashboardStore.activeDashboard && this.dashboardStore.activeDashboard.applicationId === 0
    },
    widgetOptions () {
      return this.$_.map(this.allWidgets, x => {
        return { code: x.id, label: x.widgetName }
      })
    },
    allWidgets () {
      return [
        { id: 1, widgetName: 'Search Types', componentEditor: null, apiEndpoint: 'searchType' },
        { id: 2, widgetName: 'Search Location Map', componentEditor: 'search-location-widget-editor', apiEndpoint: 'searchLocation' },
        { id: 1002, widgetName: 'Flight Departure', componentEditor: null, apiEndpoint: 'flightDeparture' },
        { id: 1006, widgetName: 'Flight Arrival', componentEditor: null, apiEndpoint: 'flightArrival' },
        { id: 1007, widgetName: 'Flight Route', componentEditor: null, apiEndpoint: 'flightRoute' },
        { id: 1008, widgetName: 'Travel Duration', componentEditor: 'travel-duration-widget-editor', apiEndpoint: 'travelDuration' },
        { id: 1009, widgetName: 'Traveller Count', componentEditor: 'traveller-count-widget-editor', apiEndpoint: 'travellers' },
        { id: 1010, widgetName: 'Search Duration', componentEditor: 'search-duration-widget-editor', apiEndpoint: 'searchDuration' },
        { id: 1012, widgetName: 'Bookings vs Baskets', componentEditor: null, apiEndpoint: 'bookingsVsBaskets' },
        { id: 1013, widgetName: 'Top Search Locations', componentEditor: 'top-search-location-editor', apiEndpoint: 'topSearchLocation' },
      ]
    },
    
  },
  mounted () {
  },
  methods: {
    fetchWidgets() {
      const storeName = this.isCompanyDashboard ? 'companyDashboardStore' : 'applicationDashboardStore'
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`${storeName}/fetchWidgets`, { 
          companyId: this.activeUserInfo.companyId,
          dashboardId: this.activeDashboard.id
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
		addDashboard () {
			const storeName = this.isCompanyDashboard ? 'companyDashboardStore' : 'applicationDashboardStore'
      
      const payload = this.isCompanyDashboard
        ? { companyId: this.activeUserInfo.companyId, data: { name: this.newDashboardName, description: this.newDashboardDescription }}
        : { applicationId: parseInt(this.applicationId), data: { name: this.newDashboardName, description: this.newDashboardDescription }}

      this.$vs.loading()
      this.$store.dispatch(`${storeName}/addDashboard`, payload )
      .then(response => { 
        this.showAddDashboardPopup = false
        this.closeAddDashboardDialog()
        this.$_notifySuccess('Successfully added dashboard')

        const newDashboard = this.$_.find(this.dashboardStore.dashboards, x =>
          x.id === response.data);
        this.$store.dispatch(`${storeName}/persistActiveDashboard`, newDashboard)
        this.$store.commit(`${storeName}/CLEAR_WIDGET_LIST`)
        this.$vs.loading.close()

      })
      .catch(error => console.error(error))
      .finally()
		},
		closeAddDashboardDialog () {
      this.newDashboardName = '';
      this.newDashboardDescription = '';
    },
		editDashboard () {
      const routeName = this.isCompanyDashboard ? 'company-dashboard-edit' : 'application-dashboard-edit'
      const params = this.isCompanyDashboard
        ? { companyId: this.activeUserInfo.companyId , dashboardId: this.activeDashboard.id, isCompanyDashboard: this.isCompanyDashboard }
        : { applicationId: parseInt(this.applicationId), dashboardId: this.activeDashboard.id, isCompanyDashboard: this.isCompanyDashboard } 
        
      this.$router.push({ name: routeName, params: params })
    },
    deleteDashboard () {
      const storeName = this.isCompanyDashboard ? 'companyDashboardStore' : 'applicationDashboardStore'
      this.$vs.loading()
      this.$store.dispatch(`${storeName}/deleteDashboard`, { 
        companyId: this.activeUserInfo.companyId,
        dashboardId: this.activeDashboard.id
      })
      .then(response => { 
        this.showDeleteDashboardPopup = false
        this.$_notifySuccess('Successfully deleted dashboard')
        this.$store.dispatch(`${storeName}/setActiveDashboardToDefault`)
        this.fetchWidgets()
          .finally(() => this.$vs.loading.close())
      })
      .catch(error => {
        this.$_notifyFailureByResponseData(error.response.data)
        this.$vs.loading.close()
      })
      
    },
    addWidget () {
      if (!this.selectedWidget) {
        this.$_notifyFailure('Please select a widget to add')
        return
      }

      const routeName = this.isCompanyDashboard ? 'company-dashboard-widget-add' : 'application-dashboard-widget-add'

      const widget = this.$_.find(this.allWidgets, x => x.id === this.selectedWidget.code)
      this.$router.push({ 
        name: routeName, 
        params: { 
          companyId: this.activeUserInfo.companyId,
          dashboardId: this.activeDashboard.id,
          widgetInstanceId: 0,
          componentEditor: widget.componentEditor,
          apiEndpoint: widget.apiEndpoint,
          widgetId: widget.id,
          widgetName: widget.widgetName,
          isCompanyDashboard: this.isCompanyDashboard,
          applicationId: this.activeDashboard && this.activeDashboard.applicationId,
          hideDateRange: widget.id == 1012,
          hidePresetRange: widget.id == 1013
        }
      })
    },
    closeAddWidgetDialog () {
      this.selectedWidget = null
    },
  }
}
</script>

<style lang="scss">
/* Succeding styles fixes wrapping for long text- https://github.com/sagalbot/vue-select/issues/754 */
.company-dashboard-select-container {
	.vs__selected-options {
		flex-wrap: nowrap;
		max-width: calc(100% - 41px);
	}
	.vs__selected {
		display: block;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 100%;
		overflow: hidden;
	}
	.vs__search {
		position: absolute;
		height: 100%;
	}
	.vs--open {
		.vs__search {
			position: static;
		}
	}
	.vs__dropdown-option {
		white-space: normal;
	}
}
</style>